<!--商品规格-->
<template>
    <div class="mainBox">
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <!-- <el-input placeholder="搜索规格名称" v-model="select.search" size="small" style="width:300px;" @change="curr=1;getList()" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff" @click="curr=1;getList()"></el-button>
                </el-input> -->
                <el-button type="primary" block size="small" @click="edit()">添加规格</el-button>
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px );">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="rule_name" label="规格名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="attr_name" label="商品规格" show-overflow-tooltip></el-table-column>
                <el-table-column prop="attr_value" label="商品属性" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-for="(item,index) in scope.row.attr_value" :key="index">{{item}}, </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="240">
                    <!-- <template slot="header">
                        <el-input placeholder="搜索规格名称" v-model="select.searchValue" size="small" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="curr=1;getList()"></el-button>
                        </el-input>
                    </template> -->
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <!-- 新建框 -->
        <specAdd ref="specAdd" @success="getList()" :title="title"></specAdd>
    </div>
</template>
<script>
    import {specListApi,specReadApi,specDeldApi}  from '@/api/spec.js';
    import specAdd from "@/components/specAdd.vue"; //新建索引弹框组件
    export default {
        data() {
            return {
                select:{},
                list:[], //商品列表

                title:'新建规格',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.init();
        },
        components:{
            specAdd
        },
        methods: {
            init: function(){
                //列表数据
                this.getList();
            },
            //商品列表
            getList: function(){
                specListApi({
                    row:this.row,
                    curr:this.curr
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },

            //编辑商品
            edit: function(param = {}){
                this.$refs.specAdd.open();
                //判断添加商品or编辑商品
                if(typeof param.id == 'undefined'){
                    this.title = '添加规格';
                    this.$refs.specAdd.formData = {
                        id:0,
                        rule_value:[]
                    };
                }else {
                    this.title = '编辑规格';
                    this.$refs.specAdd.formData={};
                    specReadApi({
                        id:param.id
                    }).then(response=>{
                        //整合商品规格属性数据
                        for(let ruleValue of response.rule_value){
                            this.$set(ruleValue,'detailObject',[])
                            for(let detail of ruleValue.detail){
                                ruleValue.detailObject.push({
                                    value:detail
                                })
                            }
                        }
                        this.$refs.specAdd.formData = JSON.parse(JSON.stringify(response));
                    })
                }
            },
            //删除商品
            deletes:function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    specDeldApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{})
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>