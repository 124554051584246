<template>
    <div class="specDialog">
        <el-dialog :title="title" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="760px">
            <el-form ref="formAuth" :rules="formRules" :model="formData"  size="small" class="alertForm">
                <el-form-item label="商品分类" prop="cate_id" style="width:100%">
                    <el-select v-model="formData.cate_id" placeholder="选择商品分类" clearable>
                        <el-option v-for="(item,index) in selectData['商品分类']" :label="item.cate_name" :value="item.id" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="规格模板名称" prop="rule_name" style="width:100%">
                    <el-input v-model="formData.rule_name" placeholder="规格模板名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item v-for="(item,index) in formData.rule_value" :key="index" style="width:100%">
                    <el-row type="flex" align="middle">
                        {{item.value}}
                        <el-image fit="cover" class="close_img" @click="clearSpec(index)" :src="require('../assets/close.png')"></el-image>
                    </el-row>
                    <el-row>
                        <el-input v-for="(vitem,vIndex) in item.detailObject" :key="vIndex" v-model="vitem.value" size="mini" clearable @clear="clearAttribute(index,vIndex)" autocomplete="off" style="width:100px;margin-right:5px"></el-input>
                        <el-button type="primary" size="mini" plain @click="addAttribute(index)">添加属性</el-button>
                    </el-row>
                </el-form-item>
            </el-form>
    
            <el-form v-if="isBtn" ref="specAuth" :rules="specRules" :model="specForm"  size="small" class="alertForm">
                <el-form-item label="规格名称" prop="specName" style="width:38%">
                    <el-input v-model="specForm.specName"  autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="规格值" prop="specVal" style="width:38%">
                    <el-input v-model="specForm.specVal" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item v-if="isBtn" label=" " style="width:22%;margin-right:0" class="flex flex-ai-b">
                    <el-button type="primary" plain size="mini" @click="submitSpec()">保存</el-button>
                    <el-button type="primary" plain size="mini" @click="specForm.specName = '';specForm.specVal = '';isBtn = false">取消</el-button>
                </el-form-item>
            </el-form>
        
            <el-button type="success" v-if="!isBtn" size="small" @click="addSpec()" icon="el-icon-plus">添加新规格</el-button>

            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {specEditApi,shopTypeListApi}  from '@/api/spec.js';
    export default {
        data() {
            return {
                formData:{
                    rule_value:[]
                },
                selectData:[],//下拉
                specForm:{},
                specRules:{
                    specName: [{
                        required: true,
                        message: '请输入规格名称',
                        trigger: 'change'
                    }],
                    specVal: [{
                        required: true,
                        message: '请输入规格值',
                        trigger: 'change'
                    }],
                }, //规格值必填设置
                formRules:{
                     cate_id: [{
                        required: true,
                        message: '请选择商品分类',
                        trigger: 'change'
                    }],
                    rule_name: [{
                        required: true,
                        message: '请输入规格模板名称',
                        trigger: 'blur'
                    }],
                },
                dialogFormVisible:false,
                isBtn:false
            }
        },
        props: [
            'title',
        ],
        mounted() {
            this.getType();
        },
        methods: {
            //商品分类
            getType: function(){
                shopTypeListApi().then(response=>{
                    this.selectData['商品分类'] = response.category;
                })
            },
            /**
             * 设置规格
            */
            addSpec: function(){
                this.isBtn = true;
            },
            /**
             * 添加规格
            */
            submitSpec: function(){
                this.$refs['specAuth'].validate((valid) => {
                    if (valid) {
                        this.isBtn = false;
                        this.formData.rule_value.push({
                            value:this.specForm.specName,
                            detailObject:[{
                                value:this.specForm.specVal
                            }]
                        });
                        this.specForm.specName = '';
                        this.specForm.specVal = ''
                    }
                })
           },
            /**
             * 设置属性
            */
            addAttribute: function(index){
                let idx = this.formData.rule_value[index].detailObject.length - 1;
                let arr = {
                    value:''
                }
                if (this.formData.rule_value[index].detailObject.length == 0) {
                    this.formData.rule_value[index].detailObject.push(arr);
                }else{
                    if (!this.formData.rule_value[index].detailObject[idx].value) {
                        this.$message({
                            message: '商品属性不能有空！',
                            type: 'error'
                        })
                        return;
                    } else {
                        this.formData.rule_value[index].detailObject.push(arr)
                    }
                }
                
            },

            //清空选中属性
            clearAttribute: function(index,vIndex){
                this.formData.rule_value[index].detailObject.splice(vIndex,1)
            },

            //清空选中规格
            clearSpec: function(index){
                this.formData.rule_value.splice(index,1)
            },

            //提交商品规格
            submit:function(){
                this.$refs['formAuth'].validate((valid) => {
                    if (valid) {
                        let specAttr = [];
                        for(let ruleValue of this.formData.rule_value){
                            this.$set(ruleValue,'detail',[]);
                            for(let detail of ruleValue.detailObject){
                                ruleValue.detail.push(detail.value)
                            }
                            specAttr.push({
                                value:ruleValue.value,
                                detail:ruleValue.detail
                            })
                        }
                        console.log(specAttr)
                        if(specAttr.length == 0){
                            this.$message({
                                message: '请至少添加一个商品规格！',
                                type: 'error'
                            });
                            return;
                        }
                        specEditApi({
                            id:this.formData.id,
                            rule_name:this.formData.rule_name,
                            rule_value:specAttr,
                            cate_id:this.formData.cate_id
                        }).then(()=>{
                            this.$message({
                                message: '商品规格添加成功！',
                                type: 'success'
                            })
                            this.success();
                        })
                    }
                })
            },
            success: function() {
                this.$emit('success');
                this.dialogFormVisible = false;
            },
            close: function() {
                this.$refs['formAuth'].resetFields();
                this.$refs['specAuth'].resetFields();
                this.dialogFormVisible = false;
                this.$emit('close');
            },
            open: function() {
                this.dialogFormVisible = true;
            },
        },
    }
</script>