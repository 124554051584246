import request from '@/http.js'

/**
 * @description 商品规则管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function specListApi (param) {
    return request.post(
        '/admin.product.rule/getList',
        param
    )
}

/**
 * @description 编辑商品规则
 * @param {Object} param data {Object} 传值参数
 */
 export function specEditApi (param) {
    return request.post(
        '/admin.product.rule/edit',
        param
    )
}

/**
 * @description 读取商品规则
 * @param {Object} param data {Object} 传值参数
 */
 export function specReadApi (param) {
    return request.post(
        '/admin.product.rule/read',
        param
    )
}

/**
 * @description 删除商品规则
 * @param {Object} param data {Object} 传值参数
 */
 export function specDelApi (param) {
    return request.post(
        '/admin.product.rule/delete',
        param
    )
}

/**
 * @description 删除商品规则
 * @param {Object} param data {Object} 传值参数
 */
 export function specDeldApi (param) {
    return request.post(
        '/admin.product.rule/delete',
        param
    )
}

/**
 * @description 获取商品类型
 * @param {Object} param data {Object} 传值参数
 */
 export function shopTypeListApi (param) {
    return request.post(
        '/admin.product.rule/add',
        param
    )
}






